import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: 'white',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  item: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem 1.5rem 2rem 1.5rem',
  },
  image: {
    height: 66,
    width: '60%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    marginTop: '1rem',
  },
  content: {
    textAlign: 'center',
    alignItems: 'center',
    fontSize: '1.14rem',
    marginBottom: theme.spacing(2),
  },
  content1: {
    fontSize: '1.14rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'justify',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
  },
}));

function CoreValue() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.titleContainer}>
            <Typography variant="h4" marked="center" align="center" component="h2" className={classes.title}>
              THƯ NGỎ TỪ CEO
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography className={classes.content1}>
              Công ty TNHH Công Nghiệp Chính Đại (Chinh Dai Industrial Co., Ltd)
              chuyên Sản xuất và Kinh doanh các loại Thép xây dựng, Thép cuộn
              cán nóng, Thép cuộn cán nguội, Thép mạ, Thép hình hộp các loại.
            </Typography>
            <br />
            <Typography className={classes.content1}>
              Công ty chúng tôi được thành lập với sứ mệnh mang đến cho Quý Khách
              Hàng các sản phẩm chất lượng cao với giá cả cạnh tranh và dịch vụ tốt
              nhất. Trải qua nhiều năm xây dựng và liên tục mở rộng, đổi mới, Chính Đại
              đã trở thành một trong những nhà sản xuất thép ống lớn trong khu vực.
              Nhiều công trình trọng điểm và các dự án lớn đã và đang sử dụng sản phẩm của
              chúng tôi.
            </Typography>
            <br />
            <Typography className={classes.content1}>
              Với đội ngũ nhân lực tận tâm và chuyên nghiêp, Chính Đại cam kết thỏa mãn yêu cầu
              cao nhất của khách hàng về sản phẩm và dịch vụ.
            </Typography>
            <br />
            <Typography className={classes.content1}>
              Chúng tôi hiểu rằng “An toàn và thân thiện” với môi trường là cách duy nhất để
              phát triển bền vững. Chính vì thế, chúng tôi luôn xem việc bảo vệ môi trường
              là ưu tiên hàng đầu trong hoạt động sản xuất kinh doanh của mình. Bên cạnh đó,
              chúng tôi cam kết duy trì một môi trường làm việc an toàn và chế độ đãi ngộ
              tốt với tất cả công nhân viên.
            </Typography>
            <br />
            <Typography className={classes.content1}>
              Chúng tôi mong muốn được phục vụ Quý Khách Hàng ngày càng tốt hơn bằng nhiệt
              huyết và đam mê, để Ống Thép Chính Đại trở thành lựa chọn hàng đầu của Quý vị
              trong mọi lĩnh vực công nghiệp, dân dụng, và xây dựng.
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(useStyles)(CoreValue);
