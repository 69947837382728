import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/navbar/navbarvn';
import Banner from '../components/gioi-thieu/banner';
import GeneralIntro from '../components/gioi-thieu/gioi-thieu-chung';
import History from '../components/gioi-thieu/lich-su';
import VisionAndMission from '../components/gioi-thieu/tam-nhin-su-menh';
import Quality from '../components/gioi-thieu/chung-chi';
import Footer from '../components/homepagevn/Footer';

const GioiThieu = () => (
  <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title> GIỚI THIỆU</title>
    </Helmet>
    <Navbar />
    <Banner />
    <GeneralIntro />
    <VisionAndMission />
    <History />
    <Quality />
    <Footer />
  </>
);

export default GioiThieu;
