import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: 'white',
    marginTop: theme.spacing(14),
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    marginTop: '2rem',
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.14rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  Timeline: {
    [theme.breakpoints.down('xs')]: {
      padding: 'unset',
      marginLeft: 0,
    },
  },
}));

export default function CustomizedTimeline() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.titleContainer}>
            <Typography variant="h4" marked="center" align="center" component="h2" className={classes.title}>
              LỊCH SỬ PHÁT TRIỂN
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Timeline align="alternate" className={classes.Timeline}>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <FastfoodIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography className={classes.content} variant="h6" component="h1">
                      <b> Năm 2000 </b>
                    </Typography>
                    <Typography className={classes.content}>
                      Công ty TNHH Công Nghiệp Quang Minh được thành lập.
                      Nhà máy ống thép Quang Minh đi vào hoạt động.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    <LaptopMacIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography className={classes.content} variant="h6" component="h1">
                      <b> Năm 2002 </b>
                    </Typography>
                    <Typography className={classes.content}>
                      Công ty TNHH Công Nghiệp Chính Đại được thành lập.
                      Hoạt động trong lĩnh vực phân phối và thương mại các sản phẩm thép
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" variant="outlined">
                    <HotelIcon />
                  </TimelineDot>
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography className={classes.content} variant="h6" component="h1">
                      <b> Năm 2007 </b>
                    </Typography>
                    <Typography className={classes.content}>
                      Công ty LD thép Quang Minh – Vientiane (Lào) được thành lập.
                      Nhà máy ống thép QVS đi vào hoạt động.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" variant="outlined">
                    <HotelIcon />
                  </TimelineDot>
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography className={classes.content} variant="h6" component="h1">
                      <b> Năm 2010 </b>
                    </Typography>
                    <Typography className={classes.content}>
                      Sát nhập Công ty TNHH CN Quang Minh và Công ty TNHH CN Chính Đại.
                      Lấy tên Công ty TNHH CN Chính Đại.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" variant="outlined">
                    <HotelIcon />
                  </TimelineDot>
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography className={classes.content} variant="h6" component="h1">
                      <b> Năm 2011 </b>
                    </Typography>
                    <Typography className={classes.content}>
                      Nhà máy cán thép Chính Đại đi vào hoạt động.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="secondary">
                    <RepeatIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography className={classes.content} variant="h6" component="h1">
                      <b> Năm 2017 </b>
                    </Typography>
                    <Typography className={classes.content}>
                      Chiếm thị phần số 1 thép ống hộp xuất khẩu từ Việt Nam và Đông Nam Á sang
                      châu Úc.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
