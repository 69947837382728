import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const backgroundImage3 = 'https://source.unsplash.com/JZ51o_-UOY8/1200x600';
const backgroundImage4 = 'https://source.unsplash.com/gdAuwo-qj5k/1200x600';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: 'white',
    marginTop: theme.spacing(14),
  },
  item: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem 1.5rem 2rem 1.5rem',
  },
  image: {
    height: 66,
    width: '60%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    marginTop: '1rem',
  },
  subTitle: {
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    color: '#888',
    fontSize: '1.14rem',
    paddingTop: '1rem',
  },
  Vision: {
    transform: 'translate3d(0, 0, 0)',
    position: 'relative',
    minHeight: '360px',
    color: '#fff',
    overflow: 'hidden',
    transition: 'width 0.5s ease',
    backgroundImage: `url(${backgroundImage3})`,
    backgroundColor: '#444',
    backgroundBlendMode: 'overlay',
    [theme.breakpoints.up('lg')]: {
      height: '50vh',
    },
    [theme.breakpoints.down('md')]: {
      height: '360px',
    },
    '&:hover': {
      width: '80% !important',
      backgroundColor: '#444',
      backgroundBlendMode: 'overlay',

      '&:after': {
        opacity: 0,
      },

      '&:before': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0) translateY(0)',
        transition: 'opacity 1s ease, transform 1s ease 0.25s',
      },
    },

    '&:hover $tlcontent': {
      opacity: 1,
      transform: 'translateY(0)',
      transition: 'all 0.75s ease 0.5s',
    },
    '&:hover $tlbg': {
      filter: 'grayscale(0)',
    },
  },
  Mission: {
    transform: 'translate3d(0, 0, 0)',
    position: 'relative',
    height: '50vh',
    minHeight: '360px',
    color: '#fff',
    overflow: 'hidden',
    transition: 'width 0.5s ease',
    backgroundImage: `url(${backgroundImage4})`,
    backgroundColor: '#444',
    backgroundBlendMode: 'overlay',
    [theme.breakpoints.up('lg')]: {
      height: '50vh',
    },
    [theme.breakpoints.down('md')]: {
      height: '360px',
    },
    '&:hover': {
      width: '80% !important',
      backgroundColor: '#444',
      backgroundBlendMode: 'overlay',

      '&:after': {
        opacity: 0,
      },

      '&:before': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0) translateY(0)',
        transition: 'opacity 1s ease, transform 1s ease 0.25s',
      },
    },
    '&:hover $tlcontent': {
      opacity: 1,
      transform: 'translateY(0)',
      transition: 'all 0.75s ease 0.5s',
    },
    '&:hover $tlbg': {
      filter: 'grayscale(0)',
    },
  },
  tlbg: {
    transform: 'translate3d(0, 0, 0)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'filter 0.5s ease',
    filter: 'grayscale(100%)',
  },
  tlyear: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    zIindex: 1,
    borderTop: '1px solid #fff',
    borderBottom: '1px solid #fff',
    fontSize: '1.14rem',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
  },
  tlcontent: {
    fontSize: '1.14rem',
    transform: 'translate3d(0, 0, 0) translateY(25px)',
    position: 'relative',
    zIndex: 1,
    textAlign: 'center',
    margin: '0 1.618em',
    top: '35%',
    [theme.breakpoints.up('lg')]: {
      opacity: 0,
      '&:hover': {
        opacity: 1,
      },
    },
    [theme.breakpoints.down('md')]: {
      opacity: 1,
    },
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
  },
  titleContainer: {
    marginBottom: theme.spacing(5),
  },
}));

function CoreValue() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.titleContainer}>
            <Typography variant="h4" marked="center" align="center" component="h2" className={classes.title}>
              TẦM NHÌN VÀ SỨ MỆNH
            </Typography>
          </Grid>
          <Grid item xs={0} md={1} lg={1} />
          <Grid item xs={12} md={5} lg={5} className={classes.Vision}>
            <div className={classes.tlbg} />
            <div className={classes.tlyear}>
              <p style={{
                marginBottom: 0, fontSize: '1.14rem', paddingTop: '1rem', paddingBottom: '1rem',
              }}
              >
                TẦM NHÌN
              </p>
            </div>
            <div className={classes.tlcontent}>
              <br />
              <p>
                Dẫn đầu chất lượng ống cứu hỏa tại Việt Nam và châu Á
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={5} lg={5} className={classes.Mission}>
            <div className={classes.tlbg} />
            <div className={classes.tlyear}>
              <p style={{
                marginBottom: 0, fontSize: '1.14rem', paddingTop: '1rem', paddingBottom: '1rem',
              }}
              >
                SỨ MỆNH
              </p>
            </div>
            <div className={classes.tlcontent}>
              <br />
              <p>
                Đem yên tâm cho mọi công trình
              </p>
            </div>
          </Grid>
          <Grid item xs={0} md={1} lg={1} />
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(useStyles)(CoreValue);
