import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: 'white',
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(10),
  },
  item: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem 1.5rem 2rem 1.5rem',
  },
  image: {
    height: 125,
    width: 125,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    marginTop: '1rem',
  },
  content: {
    textAlign: 'center',
    alignItems: 'center',
    fontSize: '1.14rem',
    marginBottom: theme.spacing(2),
  },
  header: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
  },
  subTitle: {
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    color: '#888',
    fontSize: '1.14rem',
    paddingTop: '1rem',
  },
  hidden: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

function Phukien() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" marked="center" align="center" component="h2" className={classes.title}>
              CÁC CHỨNG CHỈ CHẤT LƯỢNG
            </Typography>
            <Typography align="center" className={classes.subTitle}>
              CDI luôn lựa chọn những nhà cung cấp phụ kiện uy tín hàng đầu làm đối tác của mình
            </Typography>
          </Grid>
          <Grid item lg={1} xl={1} className={classes.hidden} />
          <Grid item xs={12} md={3} sm={6} lg={2} xl={2}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="https://www.rakuna.co/assets/Rakuna_Awards/NSF-1b955cb2d44ded15622a542adaa557dc39c94878ca15cb5b46ac1ced4c448d04.png"
                alt="clock"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={6} lg={2} xl={2}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="https://www.rakuna.co/assets/Rakuna_Awards/MNCup-8852768e987c249966753c6bc4c1d4b58f674add619b442e51fce50e11692db6.png"
                alt="suitcase"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={6} lg={2} xl={2}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="https://www.rakuna.co/assets/Rakuna_Awards/2016Entrepreneurs-4377117faaf9a112da076eeba89dec4b6cb46802514c7db8cbd514f56cf8c293.png"
                alt="graph"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={6} lg={2} xl={2}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="https://www.rakuna.co/assets/Rakuna_Awards/Vietchallenge-93a142b9db27900f58f182e0d5470f216a656ad83b60c7a11b5193dad719c1a1.png"
                alt="clock"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={6} lg={2} xl={2}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="https://www.rakuna.co/assets/Rakuna_Awards/WISEVietnam-21543a85d637b17106252c7a81089ebf4b465466c9f0b6d97dc7350476d68048.png"
                alt="clock"
              />
            </div>
          </Grid>
          <Grid item lg={1} xl={1} className={classes.hidden} />
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(useStyles)(Phukien);
